import config from '../config/config.json'

function HomePageSchema() {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'MusicGroup',
    name: 'Gradient',
    url: 'https://gradient.band/',
    logo: 'https://gradient.band/assets/gradient-logo.webp',
    sameAs: [config.social.instagram.url, config.social.bandsintown.url, config.social.soundcloud.url],
    genre: 'Rock'
  }
  return <script type='application/ld+json'>{JSON.stringify(schema)}</script>
}

export default HomePageSchema
