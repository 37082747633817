import tracks from '../config/soundcloud.json'

function SoundcloudPlayer() {
  return (
    <div>
      {tracks.map((item) => (
        <div key={item.id}>
          <iframe
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${item.sc_track_id}&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=false&show_reposts=false&show_teaser=true&show_artwork=true`}
            title={item.name}
            width='100%'
            height='120px'
          ></iframe>
        </div>
      ))}
    </div>
  )
}

export default SoundcloudPlayer
