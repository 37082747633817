import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import config from '../config/config.json'
import { ReactComponent as GradientIcon } from '../assets/gradient-icon.svg'
import { ReactComponent as InstagramIcon } from 'bootstrap-icons/icons/instagram.svg'
import { ReactComponent as SoundcloudIcon } from '../assets/soundcloud-icon.svg'
import { ReactComponent as BandsintownIcon } from '../assets/bandsintown-icon.svg'

function Header() {
  const [scrolled, setScrolled] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const toggleNavbar = () => setExpanded(!expanded)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 0) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className='header'>
      <Navbar className={`navbar ${expanded ? 'expanded' : ''} ${scrolled ? 'scrolled' : ''}`} expand='lg' fixed='top'>
        <Container>
          <Navbar.Brand href='/#intro'>
            <GradientIcon alt='Gradient Logo' className='d-inline-block align-top img-fluid logo' />
          </Navbar.Brand>
          <Navbar.Toggle onClick={toggleNavbar} />
          <Navbar.Collapse className='dropdown'>
            <Nav className='me-auto'>
              <Nav.Link href='/#live'>Live</Nav.Link>
              <Nav.Link href='/#music'>Music</Nav.Link>
              <Nav.Link href='/#about'>About</Nav.Link>
              <Nav.Link href='/#photos'>Photos</Nav.Link>
              <Nav.Link href='/#contact'>Contact</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href={config.social.instagram.url} target='_blank' rel='noreferrer'>
                <InstagramIcon alt='Gradient on Instagram' className='social-icon' />
              </Nav.Link>
              <Nav.Link href={config.social.soundcloud.url} target='_blank' rel='noreferrer'>
                <SoundcloudIcon alt='Gradient on Soundcloud' className='social-icon' />
              </Nav.Link>
              <Nav.Link href={config.social.bandsintown.url} target='_blank' rel='noreferrer'>
                <BandsintownIcon alt='Gradient on Bandsintown' className='social-icon' />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
