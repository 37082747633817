import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Gallery, Item } from 'react-photoswipe-gallery'
import PhotosSchema from './PhotosSchema'
import 'photoswipe/dist/photoswipe.css'

function Photos() {
  const [images, setImages] = useState([])

  useEffect(() => {
    const importAll = (r) => {
      return r.keys().map(r)
    }

    // Import all images from the images folder
    const images = importAll(require.context('../../public/assets/gallery', false, /\.(png|jpe?g|svg|webp)$/))

    // Sort images based on filenames
    images.sort((a, b) => {
      const aName = a.split('/').pop().split('.')[0]
      const bName = b.split('/').pop().split('.')[0]
      return bName.localeCompare(aName)
    })

    setImages(images)
  }, [])

  return (
    <div>
      <Gallery
        options={{
          bgOpacity: 0.85, // Set the background opacity
          showHideOpacity: true, // Animates the transition
          maxSpreadZoom: 2, // Max zoom level, images will not zoom more than twice their size
          getDoubleTapZoom: (isMouseClick, item) => {
            // Defines the zoom level when double-tapped or clicked
            return item.initialZoomLevel * 2
          },
          initialZoomLevel: 'fit', // Fits the image to the viewport while maintaining aspect ratio
          maxZoomLevel: 1, // Prevents zooming beyond the natural resolution of the image
          allowPanToNext: true // Allows panning to the next image in the gallery
        }}
      >
        <Row>
          {images.map((image, index) => (
            <Col xs={4} md={3} lg={2} key={index}>
              <Item original={image} thumbnail={image} width='1024' height='768' key={index}>
                {({ ref, open }) => (
                  <div className='thumbnail-wrapper' ref={ref} onClick={open}>
                    <img src={image} alt='' className='thumbnail-image' />
                  </div>
                )}
              </Item>
              <PhotosSchema image={image} />
            </Col>
          ))}
        </Row>
      </Gallery>
    </div>
  )
}

export default Photos
