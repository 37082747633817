import React, { useState } from 'react'
import { Player, ControlBar } from 'video-react'
import { ReactComponent as IntroLogo } from '../assets/gradient-logo.svg'

function IntroVideo() {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  const handleVideoLoad = () => {
    setIsVideoLoaded(true)
  }

  return (
    <div className='intro-video' id='intro'>
      {!isVideoLoaded && <img src='/assets/intro-loading.webp' alt='Intro wird geladen' className='loading' />}
      <Player playsInline loop autoPlay muted className='player' onLoadedData={handleVideoLoad}>
        <ControlBar disabled />
        <source src='/assets/intro.mp4' type='video/mp4' style={{ display: isVideoLoaded ? 'block' : 'none' }} />
      </Player>
      <div className='overlay' />
      <IntroLogo alt='Gradient Logo' className='logo' />
    </div>
  )
}

export default IntroVideo
