import React, { useState, useEffect } from 'react'
import config from '../config/config.json'
import bandsintown from '../config/bandsintown.json'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import LiveDatesSchema from './LiveDatesSchema'
import { ReactComponent as CollapseIcon } from 'bootstrap-icons/icons/chevron-double-right.svg'

function LiveDates() {
  const [events, setEvents] = useState([])
  const [showPastEvents, setShowPastEvents] = useState(false)

  useEffect(() => {
    const url = `${bandsintown.endpoint}/artists/id_${bandsintown.artistId}/events?app_id=${bandsintown.appId}&date=all`

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setEvents(data)
      })
      .catch((error) => console.log(error))
  }, [])

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  const now = new Date()

  const upcomingEvents = events.filter((event) => new Date(event.datetime) >= now)
  const pastEvents = events.filter((event) => new Date(event.datetime) < now)

  return (
    <div className='live-dates'>
      {upcomingEvents.length > 0 ? (
        <Table variant='dark'>
          <tbody>
            {upcomingEvents.map((gig, index) => (
              <tr key={index}>
                <td className='date'>{formatDate(gig.datetime)}</td>
                <td className='venue'>
                  {`${gig.venue.name}`}
                  <br />
                  {`${gig.venue.city}`}
                </td>
                <td className='lineup'>{gig.lineup.length === 1 && gig.lineup[0] === 'GRADIENT' ? 'Lineup tba' : gig.lineup.join(', ')}</td>
                <td className='tickets'>
                  {gig.offers.length > 0 && gig.offers[0].url ? (
                    <Button href={gig.offers[0].url} target='_blank' rel='noreferrer'>
                      Tickets
                    </Button>
                  ) : (
                    <Button variant='outline-secondary' disabled>
                      TICKETS
                    </Button>
                  )}
                </td>
                <LiveDatesSchema gig={gig} />
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className='no-dates'>Zur Zeit gibt es keine Termine. </div>
      )}
      <div className='bandsintown-follow mb-3'>
        Erfahre als Erstes von neuen Konzerten und{' '}
        <a href={config.social.bandsintown.url} target='_blank' rel='noreferrer'>
          folge uns auf Bandsintown
        </a>
        .
      </div>
      <div className='cta-main mt-3' onClick={() => setShowPastEvents(!showPastEvents)}>
        {showPastEvents ? 'Vergangene Termine ausblenden' : 'Vergangene Termine anzeigen'}
        <CollapseIcon alt='' />
      </div>
      <Collapse in={showPastEvents}>
        <div id='past-events-collapse' className='mt-3'>
          {pastEvents.length > 0 ? (
            <Table variant='dark'>
              <tbody>
                {pastEvents.map((gig, index) => (
                  <tr key={index}>
                    <td className='date'>{formatDate(gig.datetime)}</td>
                    <td className='venue'>
                      {`${gig.venue.name}`}
                      <br />
                      {`${gig.venue.city}`}
                    </td>
                    <td className='lineup'>
                      {gig.lineup.length === 1 && gig.lineup[0] === 'GRADIENT' ? 'Lineup tba' : gig.lineup.join(', ')}
                    </td>
                    <td className='tickets'>
                      {gig.offers.length > 0 && gig.offers[0].url ? (
                        <Button href={gig.offers[0].url} target='_blank' rel='noreferrer'>
                          Tickets
                        </Button>
                      ) : (
                        <Button variant='outline-secondary' disabled>
                          TICKETS
                        </Button>
                      )}
                    </td>
                    <LiveDatesSchema gig={gig} />
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className='no-dates'>Es gibt keine vergangenen Termine, die angezeigt werden können.</div>
          )}
        </div>
      </Collapse>
    </div>
  )
}

export default LiveDates
