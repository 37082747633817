import { Link } from 'react-router-dom'

function Imprint() {
  return (
    <div className='pt-5'>
      <h2>Impressum</h2>
      <h3>Angaben gemäß § 5 TMG</h3>
      <p>
        Gradient GbR
        <br />
        c/o Julian Fietze
        <br />
        Bahrenfelder Chaussee 24b
        <br />
        22761 Hamburg
        <br />
        <Link to='/#contact'>Kontakt</Link>
      </p>
      <h4>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h4>
      <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
      <h4>Haftung für Inhalte</h4>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
        überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      <h4>Haftung für Links</h4>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
        fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
        der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
        Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Links umgehend entfernen.
      </p>
      <h4>Urheberrecht</h4>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
        Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
        schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
        kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
        Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
        werden wir derartige Inhalte umgehend entfernen.
      </p>
      <h4>Externe Dienste</h4>
      <h5>Soundcloud</h5>
      <p>
        Beim Zugriff auf manche Teildienste unserer Website werden zusätzliche personenbezogene Daten verarbeitet. Dabei verarbeitete
        Datenkategorien: technische Verbindungsdaten des Serverzugriffs (IP-Adresse, Datum, Uhrzeit, abgefragte Seite,
        Browser-Informationen) und Daten über die Nutzung der Website sowie die Protokollierung von Klicks auf einzelne Elemente. Zweck der
        Verarbeitung: Auswahl von Online-Werbung auf anderen Plattformen, die mittels Real-Time-Bidding anhand des Nutzungsverhaltens
        automatisch ausgewählt werden und Übermittlung von Audio-Inhalten. Die Rechtsgrundlage für die Verarbeitung:Ihre Einwilligung nach
        Art. 6 (1) a DSGVO. Eine Übermittlung von Daten erfolgt: in gemeinsamer Verantwortung an SoundCloud Limited, Rheinsberger Str.
        76/77, 10115 Berlin, Deutschland. <a href='https://soundcloud.com/pages/privacy'>Vollständige Dateschutzerklärung von Soundcloud</a>
      </p>
      <h5>Google reCaptcha</h5>
      <p>
        Beim Zugriff auf manche Teildienste unserer Website werden zusätzliche personenbezogene Daten verarbeitet. Dabei verarbeitete
        Datenkategorien: technische Verbindungsdaten des Serverzugriffs (IP-Adresse, Datum, Uhrzeit, abgefragte Seite,
        Browser-Informationen) und Daten zur Erstellung von Nutzungsstatistiken. Zweck der Verarbeitung: Vermeidung nicht menschlicher und
        automatisierter Eingaben. Die Rechtsgrundlage für die Verarbeitung: Ihre Einwilligung nach Art. 6 (1) a DSGVO. Eine Übermittlung von
        Daten erfolgt: an den selbständigen Verantwortlichen Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Die
        Rechtsgrundlage für die Datenübermittlung an Google Ireland Limited ist Ihre Einwilligung nach Art. 6 (1) a DSGVO. Dies kann auch
        eine Übermittlung von personenbezogenen Daten in ein Land außerhalb der Europäischen Union bedeuten. Die Übermittlung der Daten in
        die USA erfolgt aufgrund Art. 45 DSGVO iVm der Angemessenheitsentscheidung C(2023) 4745 der Europäischen Kommission, da sich der
        Datenempfänger zur Einhaltung der Grundsätze der Datenverarbeitung des Data Pricacy Frameworks (DPF) verpflichtet hat.{' '}
        <a href='https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active'>
          Informationen über die DPF-Mitgliedschaft von Google Ireland Limited
        </a>
      </p>
      Quelle: https://www.e-recht24.de
    </div>
  )
}

export default Imprint
