import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import { ReactComponent as GradientLogo } from '../assets/gradient-logo.svg'

function About() {
  return (
    <div>
      <Row className='about'>
        <Col md={8} className='mb-4'>
          <Image src='/assets/about-gradient-bandphoto.webp' alt='Gradient, Rockband aus Hamburg' thumbnail fluid />
        </Col>
        <Col md={4} sm={12} className='lineup'>
          <p>
            <GradientLogo alt='Gradient Logo' className='logo' />
          </p>
          <p>
            <b>Peer</b> - Drums
            <br />
            <b>Jonas</b> - Bass
            <br />
            <b>Lisa</b> - Vocals
            <br />
            <b>Julian</b> - Gitarre
            <br />
            <b>Vincent</b> - Gitarre
          </p>
        </Col>
      </Row>
      <Row>
        <p>
          GRADIENT - die Hamburger Rockband spielt mit packenden musikalischen Verläufen und mit den Erwartungen des Publikums. Der
          gitarrenlastige Sound der Siebziger mit fetten Riffs und dicken Soli findet in der Gesangsstimme einen mehr als würdigen
          Konterpart.
        </p>
        <p>
          Mit sphärischer Klarheit und dem Zusammenspiel aus feinfühligen Tönen und energiegeladenen Passagen greift sie den Charakter
          klassischen Hardrocks auf und hebt diesen gleichzeitig auf eine neue Ebene. Unterstützt und angetrieben wird der Sound von der
          Doppelgitarren-Dynamik sowie dem druckvollen Rhythmus von Schlagzeug und Bass.
        </p>
        <p>Obligatorisch für alle, die die Einflüsse von Black Sabbath, den Red Hot Chili Peppers und Katy Perry zu schätzen wissen.</p>
      </Row>
    </div>
  )
}

export default About
