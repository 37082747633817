import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Header from './components/Header'
import Footer from './components/Footer'
import ErrorPage from './components/ErrorPage'
import IntroVideo from './components/IntroVideo'
import Homepage from './components/Homepage'
import Imprint from './components/Imprint'

import './App.scss'

function App() {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Header />
          </Col>
        </Row>
      </Container>
      <Container fluid className='intro-container'>
        <Row>
          <Col>
            <Routes>
              <Route path='/' element={<IntroVideo />} />
            </Routes>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <Routes>
              <Route path='/' element={<Homepage />} />
              <Route path='/impressum' element={<Imprint />} />
              <Route path='*' element={<ErrorPage />} />
            </Routes>
          </Col>
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default App
