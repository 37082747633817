import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer'>
      <div className='mt-2 mb-3'>
        &copy; 2024 GRADIENT GbR. <Link to='/impressum'>Impressum</Link>
      </div>
    </div>
  )
}

export default Footer
