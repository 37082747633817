import LiveDates from './LiveDates'
import SoundcloudPlayer from './SoundcloudPlayer'
import About from './About'
import Photos from './Photos'
import Contact from './Contact'
import HomePageSchema from './HomePageSchema'

function HomePage() {
  return (
    <div className='homepage'>
      <div className='mt-4 mb-3' id='live'>
        <h2>Live</h2>
        <LiveDates />
      </div>
      <div className='mt-5 mb-3' id='music'>
        <h2>Music</h2>
        <SoundcloudPlayer />
      </div>
      <div className='mt-5 mb-3' id='about'>
        <h2>About</h2>
        <About />
      </div>
      <div className='mt-5 mb-3' id='photos'>
        <h2>Photos</h2>
        <Photos />
      </div>
      <div className='mb-3' id='contact'>
        <h2>Contact</h2>
        <Contact />
      </div>
      <HomePageSchema />
    </div>
  )
}

export default HomePage
