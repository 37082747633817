function LiveDatesSchema({ gig }) {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Event',
    name: gig.venue.name,
    startDate: gig.starts_at,
    endDate: gig.ends_at,
    location: {
      '@type': 'Place',
      name: gig.venue.name,
      latitude: gig.venue.latitude,
      longitude: gig.venue.longitude,
      address: {
        '@type': 'PostalAddress',
        streetAddress: gig.venue.street_adress,
        addressLocality: gig.venue.city,
        addressRegion: gig.venue.region,
        postalCode: gig.venue.poastal_code,
        addressCountry: gig.venue.country
      }
    },
    performer: gig.lineup.map((band) => ({
      '@type': 'MusicGroup',
      name: band
    })),
    offers: gig.offers.map((offer) => ({
      '@type': 'Offer',
      url: offer.url,
      priceCurrency: 'EUR',
      availability: 'http://schema.org/InStock'
    })),
    description: `GRADIENT is playing ${gig.venue.name}.`
  }

  return <script type='application/ld+json'>{JSON.stringify(schema)}</script>
}

export default LiveDatesSchema
