function PhotosSchema(image) {
  const schema = {
    '@type': 'ImageObject',
    contentUrl: window.location.origin + image,
    name: image,
    description: `Gradient, Rockband aus Hamburg`,
    thumbnailUrl: window.location.origin + image
  }
  return <script type='application/ld+json'>{JSON.stringify(schema)}</script>
}

export default PhotosSchema
