import React, { useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha'
import recaptchaconfig from '../config/recaptcha.json'
import mailconfig from '../config/emailjs.json'

function Contact() {
  const [senderName, setSenderName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [recaptchaValue, setRecaptchaValue] = useState(null)

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value)
  }

  const sendEmail = (e) => {
    e.preventDefault()

    if (!recaptchaValue) {
      alert('Bitte lösen Sie das reCAPTCHA um eine Email zu senden.')
      return
    }

    setIsLoading(true)

    emailjs
      .send(
        mailconfig.serviceId,
        mailconfig.templateId,
        {
          from_name: senderName,
          from_email: email,
          message: message
        },
        mailconfig.userId
      )
      .then(
        (result) => {
          console.log('Email successfully sent!', result.text)
          setShowSuccessMessage(true)
          setIsLoading(false)
        },
        (error) => {
          console.log('Failed to send the email.', error.text)
          setShowSuccessMessage(false)
          setIsLoading(false)
        }
      )
  }

  const resetForm = () => {
    setSenderName('')
    setEmail('')
    setMessage('')
    setShowSuccessMessage(false)
  }

  return (
    <>
      {showSuccessMessage ? (
        <div>
          <p>Email erfolgreich versendet.</p>
          <Button
            onClick={() => {
              resetForm()
            }}
            className='send-mail'
          >
            Weitere Mail senden
          </Button>
        </div>
      ) : (
        <Form onSubmit={sendEmail} className='contact-form'>
          <Form.Group controlId='formBasicName'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Dein Name'
              value={senderName}
              onChange={(e) => setSenderName(e.target.value)}
              required
              disabled={isLoading}
              className='mb-2'
            />
          </Form.Group>
          <Form.Group controlId='formBasicEmail'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              placeholder='email@email.de'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
              className='mb-2'
            />
          </Form.Group>
          <Form.Group controlId='formBasicMessage'>
            <Form.Label>Nachricht</Form.Label>
            <Form.Control
              as='textarea'
              rows={5}
              placeholder='Hier könnte dein Text stehen.'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              disabled={isLoading}
              className='mb-2'
            />
          </Form.Group>

          <ReCAPTCHA sitekey={recaptchaconfig.siteKey} onChange={onRecaptchaChange} theme='dark' />

          <Button type='submit' className='send-mail mt-2' disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                &nbsp;
                <span className='ml-2'>Senden...</span>
              </>
            ) : (
              'Senden'
            )}
          </Button>
        </Form>
      )}
    </>
  )
}

export default Contact
